<template>
  <footer id="footer">
    <div class="call">
      <div class="inner w_hide">
        <dl>
          <dt>국제전화/위성통신서비스 고객센터</dt>
          <dd>
            <span>1599-00700(유료)</span>
            <span>/</span>
            <span>080-89-00700(무료)</span>
          </dd>
        </dl>
        <dl>
          <dt>SK 7mobile 고객센터</dt>
          <dd>
            <span>1599-0999(유료)</span>
            <span>/</span>
            <span>114(가입자휴대폰 무료)</span>
          </dd>
        </dl>
      </div>
      <div class="inner m_hide">
        <dl>
          <dt>국제전화/위성통신서비스 고객센터</dt>
          <dd>
            <a href="tel:1599-00700">1599-00700(유료)</a><span>/</span
            ><a href="tel:080-89-00700">080-89-00700(무료)</a>
          </dd>
        </dl>
        <dl>
          <dt>SK 7mobile 고객센터</dt>
          <dd>
            <a href="tel:1599-0999">1599-0999(유료)</a><span>/</span
            ><a href="tel:114">114(가입자휴대폰 무료)</a>
          </dd>
        </dl>
      </div>
    </div>
    <!--End footer 상단 영역  -->

    <!--Start footer 중간 영역  -->
    <div class="menu">
      <div class="menu-wrap">
        <div
          v-for="list in footerGnb"
          :key="list.className"
          :class="`${list.className}`"
        >
          <h4>{{ list.title }}</h4>
          <ul>
            <li v-for="child in list.children" :key="child.id">
              <router-link :to="child.path">
                {{ child.pathName }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--End footer 중간 영역  -->

    <!--Start footer 하단 영역  -->
    <div class="last">
      <div class="inner">
        <div class="footer_info">
          <div class="info">
            <h2>
              <img src="/content/images/footer_logo.png" alt="SK telink" />
            </h2>
            <ul id="footerUlArea">
              <li>
                <a
                  target="_blank"
                  title="sk국제전화 00700 새창열림"
                  href="https://www.00700.com/policy"
                >
                  국제전화 이용 약관
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  title="sk 7mobile 새창열림"
                  href="https://www.sk7mobile.com/util/privacy/clauseWeb.do"
                >
                  SK7mobile 이용 약관
                </a>
              </li>
              <li>
                <router-link
                  to="/privacy/intro"
                  target="_blank"
                  title="새 창 열림"
                  class="point"
                >
                  <b>개인정보 처리 방침</b>
                </router-link>
              </li>
              <li>
                <a
                  target="_blank"
                  title="sk 7mobile 새창열림"
                  href="https://www.sk7mobile.com/main.do?telFocus=stl"
                >
                  통신자료 제공사실 열람
                </a>
              </li>
              <li>
                <router-link to="/view/customer/privacy_guide">
                  이용자 피해 예방 가이드
                </router-link>
              </li>
              <li>
                <!-- todo: 팝업창 오픈 -->
                <a id="footerResponsibility" @click="handlePopupLegal">
                  책임의 한계와 법적 고지
                </a>
              </li>
              <!-- <li> -->
              <!-- todo: 팝업창 오픈 -->
              <!-- <a -->
              <!--   id="footerEmail" -->
              <!--   @click="popup.emailRefusal.isVisible = true" -->
              <!-- > -->
              <!--   이메일 무단수집거부 -->
              <!-- </a> -->
              <!-- </li> -->
              <li>
                <a
                  target="_blank"
                  title="명의도용방지서비스 새창열림"
                  href="https://www.msafer.or.kr/index.do"
                >
                  명의도용 방지 서비스
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  title="윤리경영 새창열림"
                  href="https://ethics.sk.co.kr/"
                  class="point"
                >
                  <span style="color: red">윤리경영</span>
                </a>
              </li>
            </ul>
            <div class="blog_area">
              <a
                href="https://blog.naver.com/sktelink2020"
                target="_blank"
                title="SK텔링크 공식 블로그"
              >
                <img
                  src="/content/images/ico_blog.png"
                  alt="SK텔링크 공식 블로그"
                />
              </a>
            </div>
            <div class="info_list">
              <address>(04212) 서울시 마포구 마포대로 144 마포T타운</address>
              <dl>
                <dt>사장 최영찬</dt>
              </dl>
              <dl>
                <dt>사업자 번호</dt>
                <dd>104-81-43391</dd>
              </dl>
              <!-- 20220804 주석처리 <a
                target="_blank"
                class="point2"
                href="https://ethics.sk.co.kr/Kor/Main.aspx"
              >
                윤리경영
              </a> -->
            </div>
          </div>
          <div class="family_site">
            <a
              class="family_site_anchor"
              target="_blank"
              title="방송통신이용자정보포털 : 와이즈유저 새창열림"
              href="http://www.wiseuser.go.kr"
            >
              <img
                src="/content/images/footer_banner_wise.png"
                alt="방송통신이용자정보포털 와이즈유저 마크"
              />
            </a>
            <FamilySite
              :anchor-text="anchorText"
              :family-site-list="familySiteList"
            />
          </div>
        </div>
        <div class="banners" title="SK텔링크 고객만족지수 수상내역 안내">
          <ul class="banner_list">
            <li
              class="banner_list_item cursor_pointer"
              @click="popup.award.isVisible = true"
            >
              <img
                class="banner_img"
                src="/content/images/img_award01.png"
                alt="NCSI 국가고객만족도 1위"
              />
              <span class="banner_desc"
                >국가고객만족도<br />
                11년 연속1위</span
              >
            </li>
            <li
              class="banner_list_item cursor_pointer"
              @click="popup.award.isVisible = true"
            >
              <img
                class="banner_img"
                src="/content/images/img_award02.png"
                alt="KS-SQI 한국서비스품질 지수 1위"
              />
              <span class="banner_desc"
                >한국서비스품질지수<br />
                16년 연속1위</span
              >
            </li>
            <li
              class="banner_list_item cursor_pointer"
              @click="popup.award.isVisible = true"
            >
              <img
                class="banner_img"
                src="/content/images/img_award03.png"
                alt="KCSI 한국산업의 고객만족도1위"
              />
              <span class="banner_desc"
                >한국산업의고객만족도<br />10년 연속1위</span
              >
            </li>
            <li
              class="banner_list_item cursor_pointer"
              @click="popup.award.isVisible = true"
            >
              <img
                class="banner_img"
                src="/content/images/img_award04.png"
                alt="NBCI 국가브랜드 경쟁력지수 1위"
              />
              <span class="banner_desc"
                >국가브랜드경쟁력지수<br />16년 연속1위</span
              >
            </li>
            <li
              class="banner_list_item cursor_pointer"
              @click="popup.award.isVisible = true"
            >
              <img
                class="banner_img"
                src="/content/images/img_award05.png"
                alt="KS-PBI 프리미엄 브랜드지수 1위"
              />
              <span class="banner_desc"
                >프리미엄브랜드지수<br />9년 연속1위</span
              >
            </li>
            <li
              class="banner_list_item cursor_pointer"
              @click="popup.award.isVisible = true"
            >
              <img
                class="banner_img"
                src="/content/images/img_award06.png"
                alt="KNPS 고객이 가장 추천하는 기업 1위"
              />
              <span class="banner_desc"
                >고객이 가장 추천하는<br />기업 5년 연속1위</span
              >
            </li>
            <li
              class="banner_list_item cursor_pointer many"
              @click="popup.qualification.isVisible = true"
            >
              <img
                class="banner_img"
                src="/content/images/img_award07.png"
                alt="대량문자 전송자격인증 에스케이텔링크"
              />
              <span class="banner_desc"
                >대량문자 전송자격인증<br />에스케이텔링크</span
              >
            </li>
          </ul>
        </div>
        <p>Copyright © <span>SK Telink CO,LTD.</span> All Rights Reserved.</p>
      </div>
    </div>

    <Popup
      :is-visible.sync="popup.emailRefusal.isVisible"
      :pop-title="'이메일 무단 수집거부'"
      @click="popup.emailRefusal.isVisible = false"
      @close="popup.emailRefusal.isVisible = false"
    >
      <h4 class="title">이메일 무단 수집거부</h4>
      <p>
        본 웹사이트에 게시된 이메일 주소가 전자우편 수집프로그램이나 그 밖의
        기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시
        정보통신망법에 의해 형사 처벌됩니다.
      </p>
      <p class="txt_point01">게시일 : 2006년 12월 22일</p>
    </Popup>

    <popup-big
      :pop-heading="'수상내역'"
      :btn-show="false"
      :is-visible.sync="popup.award.isVisible"
      @click="popup.award.isVisible = false"
      @close="popup.award.isVisible = false"
    >
      <div class="award_cont">
        <div class="award_title">
          <h4 class="title">
            SK텔링크 고객만족지수<br />
            수상 내역
          </h4>
        </div>
        <div class="award_txt">
          안녕하세요. SK텔링크입니다. <br /><span class="txt_point01"
            >고객님의 성원에 힘입어 국제전화 부문 주요 고객만족지수에서 모두
            1위를 차지했습니다. <br />
            (NCSI, KS-SQI, KCSI, NBCI, KS-PBI, KNPS)</span
          ><br />
          앞으로도 SK텔링크는 변치 않는 고객중심 경영을 실천하겠습니다.<br />감사합니다.
        </div>
        <div class="award_list">
          <ul>
            <li>
              <div class="img_box">
                <img src="/content/images/img_award01.png" alt="NCSI 이미지" />
              </div>
              <div class="txt_box">
                <span class="category">한국생산성본부</span>
                <h5 class="title">국가고객만족도 1위(NCSI)</h5>
                <p class="year">
                  2006, 2007, 2008, 2009, 2010, 2011, 2014, 2015, 2016, 2017,
                  2018, 2019, 2020, 2021, 2022, 2023, 2024
                </p>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img
                  src="/content/images/img_award02.png"
                  alt="KS-SQI 이미지"
                />
              </div>
              <div class="txt_box">
                <span class="category">한국표준협회</span>
                <h5 class="title">한국서비스품질지수 1위(KS-SQI)</h5>
                <p class="year">
                  2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                  2019, 2020, 2021, 2022, 2023, 2024
                </p>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="/content/images/img_award03.png" alt="KCSI 이미지" />
              </div>
              <div class="txt_box">
                <span class="category">한국능률협회컨설팅</span>
                <h5 class="title">한국산업의 고객만족도 1위(KCSI)</h5>
                <p class="year">
                  2009, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
                  2024
                </p>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="/content/images/img_award04.png" alt="NBCI 이미지" />
              </div>
              <div class="txt_box">
                <span class="category">한국생산성본부</span>
                <h5 class="title">국가브랜드경쟁력지수 1위(NBCI)</h5>
                <p class="year">
                  2005, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017,
                  2018, 2019, 2020, 2021, 2022, 2023, 2024
                </p>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img
                  src="/content/images/img_award05.png"
                  alt="KS-PBI 이미지"
                />
              </div>
              <div class="txt_box">
                <span class="category">한국표준협회</span>
                <h5 class="title">프리미엄브랜드지수 1위(KS-PBI)</h5>
                <p class="year">
                  2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
                </p>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="/content/images/img_award06.png" alt="KNPS 이미지" />
              </div>
              <div class="txt_box">
                <span class="category">한국능률협회컨설팅</span>
                <h5 class="title">고객이 가장 추천하는 기업 1위(KNPS)</h5>
                <p class="year">2016, 2017, 2018, 2019, 2020</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </popup-big>
    <popup-big
      :pop-heading="'인증서'"
      :btn-show="false"
      :is-visible.sync="popup.qualification.isVisible"
      @click="popup.qualification.isVisible = false"
      @close="popup.qualification.isVisible = false"
      class="qualification_popup"
    >
      <div class="qualification_cont">
        <img
          src="/content/images/img_qualification.jpg"
          alt="대량문자 전송자격인증서"
        />
      </div>
    </popup-big>
  </footer>
</template>

<script>
import FamilySite from "@/components/FamilySite";
import Popup from "@/components/popup/Popup";
import PopupBig from "@/components/popup/PopupBig";

export default {
  name: "Footer",
  components: {
    FamilySite,
    Popup,
    PopupBig,
  },
  data() {
    return {
      popup: {
        emailRefusal: {
          isVisible: false,
        },
        legal: {
          isVisible: false,
        },
        privacy: {
          isVisible: false,
        },
        award: {
          isVisible: false,
        },
        qualification: {
          isVisible: false,
        },
      },
      anchorText: "관계사 사이트",
      familySiteList: [
        {
          text: "SK텔레콤",
          link: "http://www.sktelecom.com",
          title: "SK텔레콤 새창열림",
        },
      ],
      footerGnb: [
        {
          title: "국가대표 국제전화 00700",
          className: "first",
          children: [
            {
              id: 1,
              path: "/view/business/00700intro",
              pathName: "국제전화 00700",
            },
          ],
        },
        {
          title: "글로벌 위성통신 서비스",
          className: "second",
          children: [
            { id: 1, path: "/view/business/inmarsat", pathName: "Inmarsat" },
            { id: 2, path: "/view/business/vsat", pathName: "VSAT" },
          ],
        },
        {
          title: "알뜰한 이동전화 SK 7mobile",
          className: "third",
          children: [
            { id: 1, path: "/view/business/sk7mobile", pathName: "SK 7mobile" },
          ],
        },
      ],
    };
  },
  created() {
    // window.addEventListener('load', this.mobileResize);
    // window.addEventListener('resize', this.mobileResize);
  },
  methods: {
    inquiryPopup() {},
    emailPopup() {},
    responsibilityPopup() {},
    awardPopup() {},
    handlePopupLegal() {
      this.$store.commit("ON_LEGAL_POPUP");
    },
    // mobileResize(){
    //   const mobileMatch = window.matchMedia("screen and (max-width: 1023px)");
    //   if (mobileMatch.matches) {
    //     this.isMobile = 'm_hide'
    //   } else {
    //     this.isMobile = 'w_hide'
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
#footer {
  .pop_wrap .alert .popup-inner {
    p {
      font-size: 16px;
      color: #666;
      letter-spacing: -1px;
      @media (max-width: 1023px) {
        position: initial;
      }
    }
    .txt_point01 {
      font-size: inherit;
      color: #ff630a;
    }
  }
  .pop_wrap02 .cont_term {
    p {
      font-size: 16px;
      color: #666;
      @media (max-width: 1023px) {
        position: initial;
        font-size: 14px;
      }
    }
    .txt_point01 {
      font-size: inherit;
      color: #ff630a;
    }
  }
}
</style>
